import { openDB } from 'idb';

export const getTheme = (): string => {
  const attribute = document.documentElement.getAttribute('data-bs-theme');
  return attribute === 'dark' ? '_dark' : '';
}

export const toggleTheme = async (): Promise<void> => {
  const currentTheme = document.documentElement.getAttribute('data-bs-theme');
  const db = await openDB('score-db', 1); // Adjust to match your existing db

  if (currentTheme === 'dark') {
    document.documentElement.removeAttribute('data-bs-theme');
    await db.put('user-preferences', 'light', 'theme'); // user-preferences is the store name, light is the value, and theme is the key
  } else {
    document.documentElement.setAttribute('data-bs-theme', 'dark');
    await db.put('user-preferences', 'dark', 'theme'); // user-preferences is the store name, dark is the value, and theme is the key
  }
}

export const initializeKeyboardNavigation = (): void => {
  const handleFirstTab = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      document.documentElement.style.setProperty('--focus-outline', '4px solid #0077cd');
      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleMouseDownOnce);
      window.addEventListener('touchstart', handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = () => {
    document.documentElement.style.setProperty('--focus-outline', 'none');
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.removeEventListener('touchstart', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
  };

  window.addEventListener('keydown', handleFirstTab);
}