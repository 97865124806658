import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Router } from '@vaadin/router';

import { BootstrapStyles } from './styles/shared-styles';

import { getTheme, toggleTheme, initializeKeyboardNavigation } from './helpers';

import { openDB } from 'idb';

import './pages/app-home';
import './components/header';
import './components/app-shared-modal';


const BASE_URL: string = (import.meta.env.BASE_URL).length > 2 ? (import.meta.env.BASE_URL).slice(1, -1) : (import.meta.env.BASE_URL);

@customElement('app-index')
export class AppIndex extends LitElement {

  static styles = [BootstrapStyles];
  private modal: HTMLElement | null;
  private modalBackdrop: HTMLElement | null;
  private db;
  constructor() {
    super();
    this.modal = null;
    this.modalBackdrop = null;
    this.db = null;
    initializeKeyboardNavigation();
  }

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/

    // For more info on using the @vaadin/router check here https://vaadin.com/router
    this.db = await openDB('score-db', 1, {
      upgrade(db) {
        db.createObjectStore('user-preferences');
      },
    });    
    this.modal = this.shadowRoot!.querySelector('.modal');
    this.modalBackdrop = this.shadowRoot!.querySelector('.modal-backdrop');


    if (this.modal && this.modalBackdrop) {
      const dialogResult = await this.db.get('user-preferences', 'confirmDisclaimer');
  
      //if (dialogResult !== 'true') {
        // Only display the modal if the user has not previously clicked "I Understand"
        this.modal.classList.add('d-block');
        this.modalBackdrop.classList.add('show');
        this.modalBackdrop.classList.remove('d-none');
      //}
    } else {
      // Elements not found, handle the situation
      // ...
    }



    const savedTheme = await this.db.get('user-preferences', 'theme');
    if (savedTheme && (savedTheme == 'dark')) {
      toggleTheme();
    }
    
    const router = new Router(this.shadowRoot?.querySelector('#routerOutlet'));
    router.setRoutes([
      // temporarily cast to any because of a Type bug with the router
      {
        path: BASE_URL,
        animate: true,
        children: [
          { path: '', component: 'app-home',
            action: async () => {
            if ("startViewTransition" in document) {
              await (document as any).startViewTransition();
            }
          
            await import('./pages/app-home.js');
          },         
          },
          {
            path: 'incidents',
            component: 'app-incidents',
            action: async () => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }
          
              await import('./pages/app-incidents/app-incidents.js');
            },
          },
          {
            path: 'incident/:id/:shareNow?',
            component: 'app-incident-details',
            action: async (context, commands) => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }
              await import('./pages/app-incident-details/app-incident-details.js');
              return commands.component('app-incident-details');
            },
          },
          {
            path: 'track/:id',
            component: 'app-record-details',
            action: async (context, commands) => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }
              await import('./pages/app-record-details/app-record-details.js');
              return commands.component('app-record-details');
            },
          },
          {
            path: 'track/:id/s/:snapshotId',
            component: 'app-record-snapshot',
            action: async (context, commands) => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }
              await import('./pages/app-record-snapshot/app-record-snapshot.js');
              return commands.component('app-record-snapshot');
            },
          },
          {
            path: 'track/:id/s/:snapshotId/:editnow?',
            component: 'app-record-snapshot',
            action: async (context, commands) => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }
              await import('./pages/app-record-snapshot/app-record-snapshot.js');
              return commands.component('app-record-snapshot');
            },
          },
          {
            path: 'track',
            component: 'app-track',
            action: async () => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }
          
              await import('./pages/app-track/app-track.js');
            },
          },
          {
            path: 'learn',
            component: 'app-learn',
            action: async () => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }

              await import('./pages/app-learn/app-learn.js');
            },
          },
          {
            path: 'learn/:custompath?',
            component: 'app-learn',
            action: async () => {
              if ("startViewTransition" in document) {
                await (document as any).startViewTransition();
              }
          
              await import('./pages/app-learn/app-learn.js');
            },
          }
        ],
      } as any,
    ]);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
      .then(function(registration) {
        console.log('Service Worker registered with scope:', registration.scope);
      }, function(err) {
        console.log('Service Worker registration failed:', err);
      });
  }

  }
  
  async handleUnderstand() {
    const now = new Date();
    await this.db.put('user-preferences', now, 'confirmDisclaimer');
    this.modal?.classList.remove('d-block');
    this.modal?.classList.add('d-none');
    this.modalBackdrop?.classList.add('d-none');
    this.modalBackdrop?.classList.remove('show');
  }
  
  async handleDoNotUnderstand() {
    window.location.href = "https://www.childrensnational.org/score";
  }

  render() {
    return html`
      <div>
        <main>
          <div id="routerOutlet"></div>
        </main>
      </div>
      
      <div class="modal-backdrop d-none fade"></div>
      <div class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Notice</h1>
            </div>
            <div class="modal-body">
              <p>This application will assist you in determining where a possible concussion is suspected.  It is not meant to provide a medical diagnosis nor to replace seeking help from a trained medical professional.</p><p>If the person has lost consciousness, even briefly, call 911 immediately.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary d-none" @click="${this.handleDoNotUnderstand}">Cancel</button>
              <button type="button" class="btn btn-primary text-white" @click="${this.handleUnderstand}">I Understand</button>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
