import { LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { BootstrapStyles } from '../styles/shared-styles.js';
import { getTheme, toggleTheme, initializeKeyboardNavigation } from '../helpers.js';
import { openDB } from 'idb';


@customElement('app-home')
export class AppHome extends LitElement {

  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = 'Home';
  @property() theme = '';

  static styles = [BootstrapStyles];  
  db: any; // Define the 'db' property here
    private prefdb: any;

  constructor() {
    super();
    initializeKeyboardNavigation();
  }

  async updateTheme() {
    this.theme = await getTheme();
  }

  async handleThemeToggle() {
    await toggleTheme();
    await this.updateTheme();
  }

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    this.prefdb = await openDB('score-db', 1, {
      upgrade(db) {
        db.createObjectStore('user-preferences');
      },
    });
    const savedTheme = await this.prefdb.get('user-preferences', 'theme');
    if (savedTheme && (savedTheme == 'dark')) {
      this.theme = '_dark';
    }
    await this.updateTheme();
  }

  share() {
    if ((navigator as Navigator ).share) {
      (navigator as Navigator ).share({
        title: 'SCORE 4 Brain Health',
        text: 'SCORE 4 Brain Health',
        url: 'https://score4brainhealth.org',
      });
    }
  }

  render() {
    return html`
      <app-header .activeTab="${'home'}" .theme="${this.theme}"></app-header>

      <main>
          <div class="container main-area">
            <button class="dark-mode-toggle bg-secondary" @click="${this.handleThemeToggle}">
              <img src="/assets/img/themetoggle${this.theme}.svg" class="icon">
              ${this.theme === '_dark' ? '' : ''}
            </button>
            <div class="logo-container p-3 bg-cn-blue rounded mb-0 d-none">
            	<img src="/assets/img/full_logo.png" class="img-fluid" alt="SCORE for Brain Health logo">
            </div>
            <h1 class="page-heading">SCORE 4 Brain Health</h1>
 
            <p>Developed by the experts at Children's National Hospital's SCORE program and the Matthew Gfeller Center at UNC-Chapel Hill, the SCORE 4 Brain Health app empowers caregivers, athletes, coaches and educators with a tool to identify, track, and respond to concussions. This easy to use app is designed to:</p>
            <ul>
              <li>facilitate early recognition of suspected concussions</li>
              <li>track recovery</li>
              <li>provide education and guidance about managing the recovery</li>
              <li>ensure a successful and safe return to activities</li>
            </ul>
            <p>Join us in taking a proactive step towards improving outcomes from concussions in children, adolescents, and adults.</p>
            <img src="/assets/img/photos/child_soccer.jpg" class="img-fluid mb-5 rounded bigImage" alt="Photo of child soccer player">
            <hr class="py-4">
            <h3>About SCORE 4 Brain Health</h3>
            <p>The SCORE 4 Brain Health app is a tool that offers three important elements of concussion recognition and response:</p>
            <ol>
              <li class="mb-4"><strong>Incidents:</strong> Initial recognition of a suspected concussion with guidance regarding the next important steps to take to receive appropriate care,</li>
              <li class="mb-4"><strong>Track:</strong> If a concussion has been suspected (or diagnosed by a healthcare provider), tracking the symptoms over time to assist in the recovery management of the injury, and</li>
              <li><strong>Learn:</strong> Access to a library of information to educate the user about concussion and ways to assist the recovery process.</li>
            </ol>
            <p>The app can be used for persons of any age from infant/preschoool to adult.</p>
            <div class="score-privacy-info d-flex align-items-start alert alert-warning p-2"><img src="/assets/img/eye-slash${getTheme()}.svg" class="icon icon-small me-2 mt-1"><div>No data is stored on our servers. Data is only stored on your device, unless you choose to share with someone else.</div></div>
            <img src="/assets/img/photos/child_doctors.jpg" class="img-fluid rounded mb-3 bigImage" alt="Photo of a child and their parent visiting a medical professional">
            <p class="text-center">You can learn more about the Safe Concussion Outcome Recovery & Education (SCORE) Program <a href="https://www.childrensnational.org/score" target="_blank">here <img src="/assets/img/external-link${getTheme()}.svg" class="icon icon-small"></a>.</p>
            <hr>
            <div class="cn-logo-container">
              <img src="/assets/img/cn_logo_${this.theme === '_dark' ? 'dark' : 'light'}.png" class="img-fluid" alt="Childrens National logo">
            </div>
          </div>
      </main>
    `;
  }
}
