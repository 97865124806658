import { LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { BootstrapStyles } from '../styles/shared-styles';
import { getTheme, toggleTheme, initializeKeyboardNavigation } from '../helpers';

@customElement('app-shared-modal')
export class AppSharedModal extends LitElement {
  @property({ type: Boolean }) open = false;
  static styles = [BootstrapStyles];

  render() {
	return this.open ? html`
	  <div class="modal-backdrop show"></div>
	  <div class="modal d-block" tabindex="-1">
		<div class="modal-dialog modal-dialog-centered">
		  <div class="modal-content">
			<div class="modal-header">
			  <h5 class="modal-title">Sources</h5>
			</div>
			<div class="modal-body">
			  <p>For further information, the following websites provide excellent information regarding concussion recognition and response for the non-medical provider (parent, coach, athlete, etc.)</p>
				<div><a href="https://www.cdc.gov/headsup" target="_blank">CDC - Heads Up<img src="/assets/img/external-link${getTheme()}.svg" class="icon icon-small ms-2 text-decoration-none"></a>
				</div>
				<div><a href="https://www.pedsconcussion.com/" target="_blank">PedsConcussion<img src="/assets/img/external-link${getTheme()}.svg" class="icon icon-small ms-2 text-decoration-none"></a></div>
			</div>
			<div class="modal-footer">
			  <button type="button" class="btn btn-secondary" @click="${this.close}">Close</button>
			</div>
		  </div>
		</div>
	  </div>
	` : html``;
  }

  close() {
	this.open = false;
	this.requestUpdate();
  }

  toggleModal() {
	this.open = !this.open;
	this.requestUpdate();
  }
}