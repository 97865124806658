import { LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { BootstrapStyles } from '../styles/shared-styles';

@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'Score 4 Brain Health';
  @property({ type: Boolean}) enableBack = false;
  static styles = [BootstrapStyles];
  @property({ type: String}) activeTab = '';
  
  @property() theme = '';

  constructor() {
    super();
  }
  
  connectedCallback() {
    super.connectedCallback();
    this.adjustNavbarPosition();
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.adjustNavbarPosition);
    }
  }
  
  disconnectedCallback() {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', this.adjustNavbarPosition);
    }
    super.disconnectedCallback();
  }
  
  adjustNavbarPosition = () => {
    const navbar = this.shadowRoot!.getElementById(`mainNav${this.theme}`);
    if (navbar) {
      if (window.visualViewport) {
        const viewportHeight = window.visualViewport.height;
        navbar.style.bottom = `0px`;
      } else {
        console.warn("visualViewport is not available");
      }
    }
  };


  render() {

    return html`
      <header>
        <nav id="mainNav${this.theme}" class="navbar fixed-bottom navbar-expand-lg">
          <div class="container px-0">
            <div class="w-100" id="mainMenu">
              <ul class="navbar-nav flex-row me-auto mb-0 mb-lg-0 justify-content-around align-items-stretch w-100 d-flex">
                <li class="nav-item menu-link-home flex-grow-1 w-25">
                  <a class="nav-link ${(this.activeTab == 'home'?'active':'')}" href="${(import.meta as any).env.BASE_URL}"><img src="/assets/img/menu-icon-home.svg" class="menu-icon">Home</a>
                </li>
                <li class="nav-item menu-link-incidents flex-grow-1 w-25">
                  <a class="nav-link ${(this.activeTab == 'incidents'?'active':'')}" href="${(import.meta as any).env.BASE_URL}incidents"><img src="/assets/img/menu-icon-incident.svg" class="menu-icon">Incidents</a>
                </li>
                <li class="nav-item menu-link-track flex-grow-1 w-25">
                  <a class="nav-link ${(this.activeTab == 'track'?'active':'')}" href="${(import.meta as any).env.BASE_URL}track"><img src="/assets/img/menu-icon-track.svg" class="menu-icon">Track</a>
                </li>
                <li class="nav-item menu-link-learn flex-grow-1 w-25">
                  <a class="nav-link ${(this.activeTab == 'learn'?'active':'')}" href="${(import.meta as any).env.BASE_URL}learn"><img src="/assets/img/menu-icon-learn.svg" class="menu-icon">Learn</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    `;
  }
}
